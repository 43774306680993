@import '../../../../styles/variables.scss';

.metric-chart {
  &__title {
    text-align: center;
    font-size: 12px;
    color: $light-graphite;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 130%;
    letter-spacing: 0.04em;
  }

  &__highlight {
    text-align: center;
    font-size: 16px;
    color: $dark-graphite;
    font-weight: 700;
    line-height: 130%;
  }

  &__container {
    width: 100%;
  }
}

@import '../../../styles/variables.scss';

.container-unauthorized__error {
  outline: 0;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font: 16px/1.4 sans-serif;
  text-align: center;
  font-family: $font;
  background-color: #fcfcfc;
  color: #465457;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  overflow-x: hidden;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    font-size: 45px;
    color: #8028ff;
    margin-bottom: 20px;
  }

  p {
    width: 60%;
    font-size: 25px;
    line-height: 30px;
    color: #a1a8aa;
    margin-bottom: 10px;
  }

  p span {
    color: #465457;
  }
  p a {
    text-decoration: underline;
    color: #8028ff;
  }

  .logo-img {
    width: 150px;
    height: auto;
  }

  .error-img {
    width: auto;
    height: auto;
  }

  @media (max-width: 800px) {
    p {
      width: 85%;
    }
    .error-img {
      width: 70%;
    }
  }
}

#container-unauthorized__error__agency__title {
  font-size: 3rem;
  line-height: 4rem;
  color: #465457;
  font-weight: 400;
  font-family: 'Globo', sans-serif;
}

#error-img {
  width: 750px;
  height: 220px;
  margin-top: 50px;
}

.container-unauthorized__error__content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  text-align: center;
  font-weight: 400;

  h3 {
    text-align: center;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2rem;
  }

  button {
    border-radius: 6px;
    background-color: #5d43fa;
    cursor: pointer;
    padding: 10px 15px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    width: fit-content;
    border: none;
    margin-left: auto;
    margin-right: auto;
  }
}

#container-unauthorized__error {
  outline: 0;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font: 16px/1.4 sans-serif;
  text-align: center;
  font-family: $font;
  background-color: #fcfcfc;
  color: #465457;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  position: relative;
  overflow-x: hidden;
}

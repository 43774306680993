@import '../../../styles/variables.scss';

.panel-container {
  padding: 40px 25px 40px 25px;
  line-height: 130%;

  &__header {
    padding-bottom: 30px;
    color: $middle-graphite;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.01em;
    font-style: normal;
  }

  &__section-header {
    margin-top: 10px;
    padding-bottom: 16px;
    color: $light-graphite;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.01em;
  }

  &__gray {
    background-color: $gray0-1;
  }

  &__flex {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__border-all {
    border: 1px solid $gray1-2;
    border-radius: 12px;
  }

  &__margin-bottom {
    margin-bottom: 30px;
  }
}

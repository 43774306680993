@import '../../styles/variables.scss';

.main-header {
  position: relative;
  width: 100%;
  height: 72px;
  background: $white;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  z-index: 90;

  &__wrapper {
    width: 100%;
    padding: 2px 40px 0 40px;
    box-sizing: border-box;
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      padding: 2px 16px 0 16px;
    }
  }
}

.logo {
  margin-top: 2px;
}

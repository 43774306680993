@import '../../../styles/variables.scss';
.investiment-alert {
  background-color: #f1efff;

  border-radius: 8px;
  padding: 16px;

  h5 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 10px;
    color: #35268f;
  }

  span {
    color: #352a7a;
    font-weight: 400;
    font-size: 14px;
    line-height: 18.2px;
  }
}
.investiment-input {
  margin-bottom: 10px;
  width: 65%;
}

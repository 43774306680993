.info-card {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  height: 100%;
  padding: 16px;
  padding-bottom: 0;
  border-radius: 12px !important;
  height: 140px;
  width: 270px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 0 !important;
    text-align: left;
    gap: 6px;
  }

  .metrics {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
}

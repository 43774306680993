@import '../../../../../../styles/variables.scss';

.information {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 24px;
  gap: 16px;
  background-color: $accent-shade;
  color: $accent-darker;

  span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
  }
}

.alert-card {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: left;
  height: 60px;
  padding: 16px;
  border-radius: 12px !important;
  width: 100%;
  background: #6a52fa4d !important;
  margin-top: -50px;

  &__content {
    display: flex;
    flex-direction: row;
    align-items: right;
    justify-content: right;
    padding: 0 !important;
    text-align: right;
    gap: 6px;
  }
}

@import '../../styles/variables.scss';

.competitive {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-right: 1px solid $gray1-2;
    border-bottom: 1px solid $gray1-2;
    background-color: $white;
  }

  &__tabs {
    padding: 12px 60px 0px;
    border-bottom: 1px solid $gray1-2;
  }
}

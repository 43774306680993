@import '../../../styles/variables.scss';

.competitive-filter {
  &__datepicker {
    input {
      font-size: 14px !important;
    }
  }

  &__buttons {
    border-radius: 6px !important;
    width: 121px;
    height: 40px !important;

    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 24px;

    &:hover {
      opacity: 0.8;
    }

    &--loading {
      color: $white !important;
    }

    &--primary {
      background: $violet !important;
      color: $white !important;
    }
    &--secondary {
      background: $white !important;
      color: $violet !important;
    }
  }

  &__inputs {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.04em;

    &__listbox,
    &__listbox--sigle .MuiTypography-root {
      font-weight: 400 !important;
      font-size: 14px !important;
      line-height: 20px !important;
      letter-spacing: 0.04em;
      color: $middle-graphite;
    }

    &__chip {
      background-color: $violet !important;
      color: $white !important;
      font-weight: 400;
      font-size: 13px !important;
      line-height: 18px;

      &--delete {
        fill: $white !important;
      }
    }
  }
}

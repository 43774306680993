@import '../../../styles/variables.scss';

.list-item-channel {
  &__name {
    text-transform: uppercase;
    font-family: $font;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.04em;
    color: $middle-graphite;

    &[aria-disabled='true'] {
      opacity: 0.5;
    }
  }

  &__discount {
    &--input {
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      line-height: 130% !important;
      color: $dark-graphite !important;
      border-color: $light-graphite;
      padding-right: 4px !important;
      width: 64px;
      height: 24px;

      input {
        padding: 4px 8px;
      }
    }

    &--adornment {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 130%;
      color: $light-graphite;
    }
  }
}

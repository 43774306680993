@import '../../../styles/variables.scss';
.investiment-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $gray1-2;
  box-shadow: none;
  &__title {
    margin: 25px 0px;
  }
  &__error {
    color: #600011;
    &__title {
      font-size: 15px;
      font-weight: 400;
    }
  }
  thead {
    background-color: #f6f6f6;

    h2 {
      color: #465457;
      margin: 0; // Para alinhar melhor dentro das células
      font-size: 16px;
      line-height: 24px;
    }
  }
  .table-cell {
    padding: 0px 10px;
    font-weight: 600;
  }
}

@import '../../styles/variables.scss';
// tela toda
.fullWidth {
  max-width: 100% !important;
}

.group {
  position: relative;
  max-width: 504px;

  .groupAccordion {
    box-shadow: none;
    border: 1px solid #e0e0e0;
    &__summary {
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 56px;
      min-height: 56px !important;

      &__title {
      }
      &__details {
        margin: auto;
      }
    }
    &__details {
      border-top: 1px solid #e0e0e0;
    }
    &__content {
      display: flex;
      flex-wrap: nowrap;
      padding: 0;
    }
    &__left-content {
      max-width: 50%;
      padding: 10px 30px 10px 0;
      border-right: 1px solid $gray1-2bold;
      width: 50%;
    }
    &__right-content {
      max-width: 50%;
      width: 50%;
      padding: 10px 0px 10px 30px;
    }
  }

  &__options {
    top: 0;
    position: absolute !important;
    right: 0;
    width: 24px;
    height: 24px;
    margin: 16px;
    border: none;
    background: transparent;
    cursor: pointer;
  }
}

.groupSettings {
  z-index: 1;
  border: 1px solid #d4d4d4;
  width: 173px;
  background: #fff;
  border-radius: 4px 0px 0px 0px;
  padding: 0px !important;
}

.buttonAdd {
  width: 504px;
  display: flex;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #5d43fa !important;
  text-align: center;
  align-items: center !important;
  justify-content: flex-start !important;
  background-color: transparent;
  text-transform: initial !important;
  margin-left: -5px !important;
  margin-top: 15px !important;
}

.iconAdd {
  margin-right: 10px;
}

@import '../../../styles/variables.scss';

// SUB 2
.title-version {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.subheader {
  &__wrapper {
    padding: 24px 40px 24px 40px;
  }
}

@import '../../styles/variables.scss';

.text-row {
  border: 1px solid $gray1;
  border-radius: 8px;
  padding: 0 7px;
  height: calc(100% - 4px);
  margin-top: 2px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.select-row {
  padding: 0 0;
}

.text-row-disabled {
  background: $gray0;
  border-radius: 8px;
  padding: 0 7px;
  height: calc(100% - 4px);
  margin-top: 2px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.discount {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  div,
  input {
    width: 100%;
  }
  label {
    background: $white;
  }
  span {
    position: absolute;
    left: 90%;
  }
}

.planning {
  &__discount_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__btn {
    width: 180px !important;
    height: 41px !important;
    margin: 0 20px 0 5px !important;
    min-width: 150px !important;
    border-radius: 6px;
    padding: 0 2px;
    font-size: 14.5px;

    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 8px 0 !important;
    }
  }

  &__discount__input {
    width: 100%;
  }

  &__total {
    &--container {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__grid--height {
    height: 350px;
  }

  &__form-control {
    flex-direction: row !important;
    align-items: center;
    height: 60px;
    @media screen and (max-width: 768px) {
      flex-direction: column !important;
    }
  }

  &__checkbox {
    min-width: 200px;
  }

  &__select {
    width: 244px;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin-right: 0px;
    }
  }
}

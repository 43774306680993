@import '../../../../styles/variables.scss';

.legends {
  white-space: nowrap;

  &__label-text {
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.01em;
    color: $dark-graphite !important;
  }

  &__label-dot {
    min-width: 12px;
    min-height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
}

@import '../../styles/variables.scss';

.subheader {
  display: flex;
  justify-content: center;
  width: 100%;
  background: $bg-color2;

  @media screen and (max-width: 768px) {
    height: auto;
  }

  &__wrapper {
    position: relative;
    width: 100%;
    max-width: 1800px;
    padding: 24px 40px 0 40px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 16px 16px 0 16px;
    }

    &--space {
      justify-content: space-between;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    text-align: left;
    color: $gray5;
  }
}

@import '../../../styles/variables.scss';

.list-channels {
  &__fallback--text {
    font-family: $font;
    font-size: 13px;
    line-height: 130%;
  }

  &__title {
    text-transform: uppercase;
    font-family: $font;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    color: $light-graphite;
    letter-spacing: 0.04em;

    &__name {
      &--spacing {
        width: 167px;
      }
    }

    &__discount {
      &--spacing {
        width: 67px;
      }
    }
  }
}

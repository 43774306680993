@import '../../styles/variables.scss';

.styled-tab {
  position: relative;

  &:hover {
    .styled-tab__remove {
      fill: $blue;
      opacity: 0.7;

      @media screen and (max-width: 768px) {
        opacity: 1;
      }
    }

    button[role='tab'] {
      color: $blue !important;
      opacity: 0.7;

      @media screen and (max-width: 768px) {
        opacity: 1;
      }
    }
  }

  &__button {
    position: absolute !important;
    top: 12px;
    right: 0px;
  }

  &__remove {
    // opacity: 0;
    fill: $gray3;

    @media screen and (max-width: 768px) {
      opacity: 1;
    }
  }

  &__menu {
    border-radius: 18px !important;
    &__item {
      font-family: $font !important;
    }
  }
}

@import '../../../styles/variables.scss';

.kantar-description {
  margin: 16px auto;
  text-align: center;
  font-size: 20px;
  line-height: 27px;
  max-width: 75%;
  font-family: $font !important;
  color: #465457;
}

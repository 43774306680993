@import '../../../styles/variables.scss';

// SUB 2
.title-version {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.title-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 16px;
}

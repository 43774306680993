@import '../../../styles/variables.scss';

.bottom-bar {
  z-index: 2;
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: $white;
  box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.05);

  @media screen and (max-width: 768px) {
    height: 80px;
    bottom: 60px;
  }

  &__wrapper {
    width: 100%;
    padding: 2px 40px 0 40px;
    box-sizing: border-box;
    max-width: 1800px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
}

.btn-wrapper {
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;

  span {
    font-weight: normal;
    font-size: 13px;
    text-align: center;
    color: $gray4;
  }
}

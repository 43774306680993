@import '../../styles/variables.scss';

.modal-period {
  &__info {
    width: 360px;
    height: 40px;
    left: 0px;
    top: 0px;
    font-weight: 400;
    line-height: 20px;
    font: normal normal normal 14px $font !important;
    line-height: 20px;
    color: $light-graphite;
  }
  &__title {
    height: 40px;
    left: 0px;
    top: 0px;
    font: normal normal normal 24px $font !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    background: linear-gradient(
      109.94deg,
      $violet 0%,
      $purple 0%,
      $blue 53.89%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    text-align: center;
    margin-bottom: 13px !important;
  }
}

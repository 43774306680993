$opacities: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $opacity in $opacities {
  .opacity-#{$opacity} {
    opacity: #{$opacity * 0.1} !important;
  }
}

$displays: (
  none,
  inline,
  inline-block,
  block,
  table,
  table-cell,
  table-row,
  flex,
  inline-flex,
  inherit
);

@each $display in $displays {
  .d-#{quote($display)} {
    display: #{$display} !important;
  }
}

$positions: (absolute, relative, fixed, inherit, initial, revert, static);

@each $position in $positions {
  .pos-#{quote($position)} {
    position: #{$position} !important;
  }
}

$directions: (row, column, row-reverse, column-reverse);

@each $direction in $directions {
  .flex-#{quote($direction)} {
    flex-direction: #{$direction} !important;
  }
}

$spaceamounts: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);

$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space * 4}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space * 4}px !important;
    }
  }
}

@each $space in $spaceamounts {
  .ma-#{$space} {
    margin: #{$space * 4}px !important;
  }

  .pa-#{$space} {
    padding: #{$space * 4}px !important;
  }
}

@each $space in $spaceamounts {
  .flex-grow-#{$space} {
    flex-grow: #{$space} !important;
  }
}

// estilo do texto (italico, sublinhado, negrito, etc)
$textStyles: (
  italic,
  underline,
  bold,
  normal,
  capitalize,
  uppercase,
  lowercase
);

@each $style in $textStyles {
  .text-#{quote($style)} {
    font-style: #{$style} !important;
  }
}

$dimensions: ('x', 'y');
@each $space in $spaceamounts {
  @each $dimension in $dimensions {
    .m#{$dimension}-#{$space} {
      @if $dimension == 'x' {
        margin: 0 #{$space * 4}px !important;
      } @else {
        margin: #{$space * 4}px 0 !important;
      }
    }

    .p#{$dimension}-#{$space} {
      @if $dimension == 'y' {
        padding: 0 #{$space * 4}px !important;
      } @else {
        padding: #{$space * 4}px 0 !important;
      }
    }

    .g#{$dimension}-#{$space} {
      @if $dimension == 'x' {
        gap: 0 #{$space * 4}px !important;
      } @else {
        gap: #{$space * 4}px 0 !important;
      }
    }
  }
}

$orders: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16);

@each $order in $orders {
  .order-#{$order} {
    order: #{$order} !important;
  }
}

$colors: (
  'white': $white,
  'white-warm': $bg-color,
  'white-warm2': $bg-color2,
  'gray7': $gray7,
  'gray5': $gray5,
  'gray4': $gray4,
  'gray3': $gray3,
  'gray2': $gray2,
  'gray1': $gray1,
  'gray0': $gray0,
  'gray0-1': $gray0-1,
  'gray0-2': $gray0-2,
  'yellow': $yellow,
  'pink': $pink,
  'violet': $violet,
  'soft-pink': $soft-pink,
  'red': $red,
  'black5': $black5,
  'light-graphite': $light-graphite,
  'gradient': $gradient,
  'blue': $blue
);

@each $name, $color in $colors {
  .plim-#{$name} {
    color: #{$color} !important;
  }

  .plim-bg-#{$name} {
    background: #{$color} !important;
  }
}

$aligns: (start, end, center, baseline, stretch);
$justifys: (center, start, end, space-between, space-around);

@each $align in $aligns {
  .align-#{quote($align)} {
    align-items: #{$align} !important;
  }
}

@each $justify in $justifys {
  .justify-#{quote($justify)} {
    justify-content: #{$justify} !important;
  }
}

$textAligns: (center, left, right, justify);

@each $align in $textAligns {
  .text-#{quote($align)} {
    text-align: #{$align} !important;
  }
}

/// reduzir o tamanho do texto em x porcento
/// usa o classe .text-1 para reduzir o tamanho do texto em 10%
$fontSizes: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);

@each $size in $fontSizes {
  .text-#{$size} {
    font-size: #{((($size * 10) - 100) * 0.01) * -1}em !important;
  }
}

@import '../../styles/variables.scss';

.top-sub {
  position: absolute;
  right: 40px;
  display: flex;

  @media screen and (max-width: 768px) {
    position: relative;
    right: initial;
  }

  &__inputFile {
    display: none;
  }
}

// SUB 2
.title-version {
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.version {
  cursor: pointer;
  margin-left: 24px;
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 8px;
  box-sizing: border-box;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding: 0;
    margin-bottom: 6px;
  }

  span {
    font-weight: bold;
    color: $gray4;
  }

  img {
    margin-left: 8px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
}

.client {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: $gray4;
  display: flex;
  margin: 3px 0;
}

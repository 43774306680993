@import '../../../../../styles/variables.scss';

.totals {
  background-color: $gray0-1;
  color: $black-text;
  font-size: 20px;
  font-weight: 600;
  padding: 16px;
  border-radius: 8px;
  &__border {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid;
    margin: 1em 0;
    padding: 0;
    border-color: #dbdbe8;
  }

  &__investment,
  &__impressions {
    display: flex;
    align-items: center;
    column-gap: 24px;

    &__value {
      margin-left: auto;
    }

    &__svg {
      border: 1px solid #dbdbe8;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      padding: 7px;
    }
  }
}

@import '../../../styles/variables.scss';

.date-range-picker {
  &__calendar {
    &--highlight {
      .Mui-selected {
        background-color: $violet !important;
      }
    }
  }
}

@import '../../styles/variables.scss';
.scenarios {
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    overflow-x: auto;
  }

  &__item {
    display: flex;
    position: relative;
    height: 36px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }

    span {
      display: flex;
      position: relative;
      font-weight: bold;
      cursor: pointer;
      color: $gray4;
      white-space: nowrap;
      flex-grow: 4;

      &:hover {
        color: $violet;
        opacity: 1;
        &:after {
          background: $gray3;
          opacity: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        margin: auto;
        bottom: 0;
        width: 40px;
        height: 3px;
        background: $gray4;
        border-radius: 5px 5px 0 0;
      }
    }
    &--active {
      span {
        color: $violet;

        &:after {
          opacity: 1;
          background: $violet;
        }
      }
    }
    &--disable {
      span {
        color: $gray5;
        opacity: 0.3;
        &:after {
          background: $gray5;
        }
      }
    }
  }

  &__button {
    font-size: 18px !important;
    line-height: 24.55px;
    font-weight: 400 !important;
    font-style: normal !important;
  }
}

.select-input__grid {
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 8px !important;
  height: 46px;
  margin-top: 2px;
  font-size: 14px !important;
  display: flex !important;
  align-items: flex-start !important;
  padding: 12px 0 0 8px !important;

  & > div {
    padding: 1px 24px 4px 1px !important;
  }
}

.rdg-cell {
  box-shadow: initial !important;

  input {
    border: 1px solid rgb(224, 224, 224);
    border-radius: 8px !important;
    box-sizing: border-box;
    padding: 2px 7px !important;
    width: calc(100% - 16px);
    height: calc(100% - 4px);
    margin-top: 2px;
    margin-left: 8px;
    font-size: 14px !important;
  }
}

.MuiSelect-root .MuiPaper-root {
  max-height: 200px !important;
  overflow-y: auto !important; // Enables vertical scrolling when overflowed
}

.red {
  color: red !important;
}

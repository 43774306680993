@import '../../../styles/variables.scss';

.metric-card {
  padding: 16px;
  margin: 0px 0px 30px 0px !important;
  background-color: $white !important;
  min-width: 427px;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: $dark-graphite !important;
  border: 1px solid $gray1-2 !important;
  box-shadow: none !important;

  &__content {
    height: 31px;
    width: 100%;
  }

  &__header {
    padding-bottom: 16px;
    font-size: 14px;
    color: $light-graphite;
    font-weight: 700;
    letter-spacing: 0.02em;
    text-transform: uppercase;
  }
}

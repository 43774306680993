@import '../../styles/variables.scss';

.univers {
  .univers_accordion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    position: fixed;
    width: 100%;
    bottom: 80px;
    background: $gray0-1;
    border: 1px solid $gray0-2;
    border-radius: 6px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      &.Mui-expanded {
        bottom: 140px;
      }

      bottom: -80px;
    }

    @media screen and (min-width: 769px) and (max-width: 1200px) {
      &.Mui-expanded {
        bottom: -150px;
      }

      bottom: 80px;
    }

    &__content {
      width: 100vw;
      border: 1px dashed $gray1-2 !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 24px 0px 24px;
      overflow: hidden;
    }

    &__summary {
      display: flex;
      flex-direction: column-reverse;
      flex-grow: 0;
    }

    &__details {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}

.univers-info {
  &__channel {
    font: normal normal bold 16px $font;
    line-height: 22px;
    color: $middle-graphite;
    padding: 6px;

    &--value {
      font: normal normal 400 16px $font;
      line-height: 22px;
      color: $middle-graphite;
      padding: 6px;
    }
    &--source {
      font: normal normal 300 14px $font;
      line-height: 20px;
      text-align: right;
      color: $middle-graphite;
      padding: 6px;
    }
  }

  &__divider {
    border: 1px dashed $gray1-2 !important;
  }
}

.totalizers__stats__wrapper {
  margin-right: 24px;
  text-align: center;
  color: $gray4;

  @media screen and (max-width: 1200px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  &--field {
    font: normal normal 300 20px $font;
    line-height: 31px;
    text-align: center;
    color: $gray4;
    margin-right: 4px;

    @media screen and (max-width: 1400px) {
      font-size: 18px !important;
    }

    @media screen and (min-width: 1536px) {
      font-size: 22px !important;
    }
  }

  &--value {
    font: normal normal bold 20px $font;

    @media screen and (max-width: 1400px) {
      font-size: 18px !important;
    }

    @media screen and (min-width: 1536px) {
      font-size: 22px !important;
    }
  }
}

.accordion {
  &__button {
    transform: rotate(180deg);
    &--icon {
      fill: $light-graphite;
    }
  }
}

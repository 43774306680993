@import '../../styles/variables.scss';

.grid {
  &-pagination {
    &__wrapper {
      display: block;
      position: relative;
    }

    &__loading {
      position: absolute !important;
    }

    &__header {
      display: flex;
    }

    &__toolbar > .MuiInputBase-root {
      margin-right: 6px !important;
      margin-left: 0px !important;
    }
  }

  &-col__shadow--none {
    box-shadow: none !important;
  }

  &-col__separate--none {
    border-right: none !important;
  }

  &-header {
    margin-top: 2px;

    &__align {
      &--right {
        > span {
          flex-direction: row-reverse;
          text-align: end;
        }
      }
      &--center {
        > span {
          text-align: center;
        }
      }
    }

    &__title {
      color: $gray5;
      font: normal normal bold 15px/20px $font !important;
      flex-grow: 1;
      overflow: clip;
      text-overflow: ellipsis;
    }

    &__subtitle {
      color: $gray5;
      font: normal normal normal 12px/16px $font !important;
      font-weight: normal !important;
      flex-grow: 1;
      overflow: clip;
      text-overflow: ellipsis;
    }

    &__icon {
      &--asc {
        min-width: 24px;
        transform: rotate(-180deg);
        transition: transform 200ms ease;
      }

      &--desc {
        min-width: 24px;
        transform: rotate(0deg);
        transition: transform 200ms ease;
      }

      &--export {
        align-self: flex-end;
        > svg {
          fill: $gray3;
        }
      }
    }

    &__num--rows {
      align-self: flex-end;
      width: 100%;
      color: $gray3;
      letter-spacing: 0px;
      font: normal normal normal 14px/19px $font;
    }
  }

  &-icon {
    &__expanded-more {
      transform: rotate(90deg);
      transition: transform 200ms ease;
    }

    &__expanded-less {
      transform: rotate(0deg);
      transition: transform 200ms ease;
    }
  }

  &__row {
    transition-delay: 0ms;
    transition-property: height;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }
}

.powerBiAudience {
  height: 100%;
  width: 100%;
  .circularLoading {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
  }
}

@import '../../../styles/variables.scss';

.audience-main-menu {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.audience-main-menu-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 24px;
  width: 80%;
}

.audience-main-menu-title {
  color: #616161;
  font-weight: 400;
}

.audience-main-menu__title__strong {
  font-weight: 700;
}

.audience-main-menu__title {
  font-size: 3rem;
  font-weight: 300;
  line-height: 74px;
  text-align: left;
}

.audience-main-menu-subtitle {
  font-size: 26px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 12px;
  letter-spacing: -0.02em;
  text-align: left;
  opacity: 0.7;
}

.audience-main-menu__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(20% - 16px), 1fr));
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  gap: 16px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.audience-main-menu__item {
  border: 1px solid #99a3aa;
  border-radius: 28px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 100%;
  min-width: 150px;
}

.audience-main-menu__icon {
  max-width: 45px;
  max-height: 45px;
  width: auto;
  height: auto;
}

.audience-main-menu__item:last-child {
  margin-right: 0;
}

.audience-main-menu__item__title {
  font-size: 18px;
  line-height: 20px;
  max-width: 220px;
}

.audience-main-menu__item__button {
  padding: 6px 12px;
  background-color: transparent;
  border: 2px solid $blue;
  color: $blue;
  font-weight: 600;
  font-size: 18px;
  border-radius: 50px;
  width: fit-content;
  cursor: pointer;
  margin-top: auto;
  font-family: 'Globo', sans-serif;
}

.audience-main-menu-footer {
  margin-top: 64px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}

.audience-main-menu-footer__button {
  color: $blue;
  background: transparent;
  padding: 12px;
  border: 1px solid $blue;
  border-radius: 6px;
  font-family: 'Globo', sans-serif;
  cursor: pointer;
}

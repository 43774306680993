@import '../../styles/variables.scss';

.button-app {
  &__loading {
    vertical-align: middle;
  }
}

.btn-export-disabled {
  pointer-events: none;
  cursor: default !important;
  box-shadow: none !important;
  background: $purple1 !important;
  color: $purple2 !important;
  border: none !important;
}

.btn-back {
  color: $gray4;
  background-color: transparent;
  border: none;

  &:hover {
    color: $gray5 !important;
    background-color: transparent !important;
  }
}

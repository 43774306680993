@import '../../../../../styles/variables.scss';
.digitalExport {
  &__checkbox {
    color: $black-text;
  }
  &__hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid $gray1-2bold;
    margin: 10px 0;
    padding: 0;
  }
}

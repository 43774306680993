@import '../../styles/variables.scss';

.styled-tabs {
  &__indicator {
    display: flex;
    justify-content: center;
    background-color: transparent !important;
    height: 3px !important;

    &--span {
      border-radius: 5px 5px 0 0;
      max-width: 40px;
      width: 100%;
      background-color: $blue;
    }
  }

  &__button > .styled-tab > button[role='tab'] {
    font-family: $font;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    text-transform: none;
    color: $gray4 !important;
    white-space: nowrap;
    flex-grow: 4;
    letter-spacing: normal !important;
    width: auto !important;
    margin-right: 10px;

    &.Mui-selected {
      color: $blue !important;
    }
  }
}

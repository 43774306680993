.filters__wrapp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin: 1rem 0;
}

.filters__label,
.filters__label-content {
  font-size: 14px;
  color: #465457;
}

.filter-bubble {
  background-color: #d7d7d7;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

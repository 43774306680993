@import '../../styles/variables.scss';

.info-alert {
  display: flex;
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px $info-shadow;
  border-radius: 8px;
  opacity: 1;
  padding: 7px 10px;

  &__message {
    color: $gray5;

    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
  }

  &__icon {
    min-width: 20px;
  }
}

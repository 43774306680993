@import '../../../styles/variables.scss';

.notifications {
  &__list {
    position: relative;
    max-height: 100%;
    padding: 16px 24px 24px 24px;
    flex: 1 1 auto;
    overflow: auto;

    @media screen and (max-width: 768px) {
      padding: 10px 16px 16px 16px;
    }
  }
}

.notifications-item {
  position: relative;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin-top: 16px;
  height: auto;
  min-height: 100px;
  border-radius: 16px;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  &__left {
    display: flex;
    margin-left: 12px;
    align-items: center;
    min-width: 50px;

    @media screen and (max-width: 768px) {
      margin-left: 0;
      min-width: 34px;
    }

    img {
      display: block;
    }
  }

  &__title {
    display: block;
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: $gray5;
    margin-bottom: 3px;
  }

  &__info {
    display: block;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
    color: $gray4;
  }
}

@import '../../../styles/variables.scss';
.checkbox {
  width: 100%;
  &__label {
    font: normal normal normal 14px/19px $font !important;
    letter-spacing: 0px !important;
    color: $gray3;
  }
}
.checkbox--error {
  &__label {
    font: normal normal normal 14px/19px $font !important;
    letter-spacing: 0px !important;
    color: $red !important;
  }
}

.checkbox__label--error {
  &__label {
    background-color: red;
    font: normal normal normal 14px/19px $font !important;
    letter-spacing: 0px !important;
    color: $red !important;
  }
}

.summary {
  &__grid {
    height: 400px;

    &--detail {
      height: 250px;
    }
  }
}

.border-style {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 0px #00000012;
  border-radius: 8px;
  opacity: 1;
  padding: 7px 10px;
}

@import '../../../styles/variables.scss';

.spacing {
  margin-top: 15px;
}
.content-divisor {
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.table-content {
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-top: 24px;
    padding-left: 0;
  }
}

.sub-menu {
  display: flex;

  @media screen and (max-width: 768px) {
    width: 100%;
    overflow-x: auto;
    padding-bottom: 8px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  &__item {
    margin: 0 8px 0 0;
    cursor: pointer;

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;
      border: 1px solid #eaefef;
      border-radius: 18px;

      &:focus-visible {
        outline: none;
      }

      &:hover {
        border-radius: 18px;
        background: $hover-c1;

        p {
          color: $gray5;
        }

        .cls-1 {
          fill: $gray5;
        }
      }

      p {
        font-family: $font;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        color: $gray4;
        margin: 0;
      }
    }
  }

  &--selected {
    .sub-menu__item__button {
      background: $hover-c1;
      border-radius: 18px;

      p {
        color: $gray5;
      }
    }

    .sub-menu__icon {
      .cls-1 {
        fill: url(#linear-gradient);
      }
    }
  }
}

@import '../../styles/variables.scss';

.main-menu {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    position: fixed;
    align-items: center;
    z-index: 90;
    left: 0;
    bottom: 0;
    width: 100%;
    background: $white;
    box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.05);
    height: 60px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }

  &__item {
    margin: 0 4px;
    cursor: pointer;

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 16px;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        // width: 88px;
        padding: 4px 12px;
      }

      &:focus-visible {
        outline: none;
      }

      &:hover {
        border-radius: 18px;
        background: $hover-c1;

        p {
          color: $gray5;
        }

        .cls-1 {
          fill: $gray5;
        }
      }

      p {
        font-family: $font;
        font-weight: 600;
        font-size: 14px;
        text-align: left;
        color: $gray4;
        margin: 0;

        @media screen and (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  &--selected {
    .main-menu__item__button {
      background: $hover-c1;
      border-radius: 18px;

      p {
        color: $gray5;
      }
    }

    .main-menu__icon {
      .cls-1 {
        fill: url(#linear-gradient);
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
  }
}

.digital-menu {
  flex-direction: row !important;
  gap: 10px !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.user-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    cursor: pointer;

    &:focus-visible {
      outline: none;
    }
  }

  &__item {
    position: relative;
    margin-right: 8px;
    box-sizing: border-box;
    height: 42px;

    &:hover {
      border-radius: 18px;
      background: $hover-c1;

      .cls-1 {
        fill: $gray5;
      }
    }
  }

  &--selected {
    .user-menu__button {
      background: $hover-c1;
      border-radius: 18px;
    }

    &:hover {
      .cls-1 {
        fill: url(#linear-gradient);
      }
    }

    .cls-1 {
      fill: url(#linear-gradient);
    }
  }

  &__notification {
    position: absolute;
    top: 5px;
    right: 0px;
    background: $soft-pink;
    width: 20px;
    height: 15px;
    border-radius: 7.5px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-weight: 800;
    font-size: 10px;
  }
}

.logout {
  position: relative;
  display: flex;
  margin-left: 8px;
  align-items: center;

  &__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 200px;

    @media screen and (max-width: 768px) {
      max-width: 80px;
    }

    .profile__name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    span {
      text-align: right;
      margin-right: 2px;
      line-height: 14px;

      &:first-child {
        font-size: 13px;
        text-align: right;
        color: $gray3;
      }

      &:last-child {
        font-size: 14px;
        color: $gray5;
      }
    }
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 8px;
    height: 100%;
    cursor: pointer;

    &:hover {
      border-radius: 18px;
      background: $hover-c1;

      .cls-1 {
        fill: $gray5;
      }
    }
  }
}

.menu {
  &--badge {
    top: 3px !important;
    left: -9px;
    background-color: $blue !important;
    min-width: 24px !important;
  }

  &--disabled {
    opacity: 0.38;
    cursor: default;
  }

  &__item {
    &--button-disabled {
      cursor: default;
      &:hover {
        background: none;

        .cls-1 {
          fill: $gray4;
        }
      }
    }
  }
}

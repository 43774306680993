.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &__divisor {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__divisor_select {
    position: relative;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__divisor__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

// @import '../../styles/variables.scss';

.ranking {
  grid-template-rows: 45px repeat(7, 55px) !important;
  align-items: center;

  &__grid--height {
    height: 600px;
  }

  &__grid--width {
    grid-template-rows: max-content;
  }
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  width: 80%;
  gap: 10px;
}

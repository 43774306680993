@import '../../../../../styles/variables.scss';

.avaliableImpressions {
  background-color: $gray0-1;
  padding: 24px;
  border-radius: 8px;
  &__label {
    font-size: 20px;
    font-weight: 600;
  }

  &__graphs {
    row-gap: 16px;
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    &__line {
      width: 100%;
      background-color: $purple3;
      border-radius: 20px;
      display: block;
      height: 20px;
      position: relative;
      overflow: hidden;
      &__blue {
        position: absolute;
        background-color: $blue;
        left: 0;
        z-index: 0;
        border-radius: 0;
      }
    }
    &__values {
      display: flex;
      &__infos {
        display: flex;
        align-items: center;
        column-gap: 12px;
        min-width: 120px;
        margin-right: 24px;

        &__label {
          font-size: 14px;
          font-weight: 600;
          color: $gray6;
        }

        &__color {
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          display: flex;
          &__blue {
            background-color: $blue;
          }
          &__purple {
            background-color: $purple3;
          }
        }
      }
    }
  }

  &__disclaimer {
    background-color: $accent-shade;
    color: $accent-darker;
    font-size: 14px;
    font-weight: 600;
    padding: 24px;
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__estimatedPanning {
    font-size: 16px;
    font-weight: 400;
    color: $accent-darker;
    margin-top: 23px;
  }
}

.no-data-screen {
  .no-data-container {
    text-align: start;

    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .divider {
      width: 100%;
      height: 40px;
      background-color: #f2f2f2;
      border-radius: 4px;
      margin-bottom: 20px;
    }

    .message {
      text-align: center;
      font-size: 16px;
      color: #465457;
      line-height: 1.5;
    }
  }
}

.graphContainer {
  width: 100%;
  margin: 20px auto;
  border-radius: 8px;
  padding: 24px;
  background-color: #fff;
  box-shadow: none !important;
  border: 1px solid #e6e8eb !important;
  border-radius: 16px !important;
}

@import '../../styles/variables.scss';

.filters {
  &__loading {
    position: absolute;
  }

  &__chip-toggle-icon {
    &--expanded-more {
      transform: rotate(0deg);
      transition: transform 200ms ease;
      fill: $white;
    }

    &--expanded-less {
      transform: rotate(-180deg);
      transition: transform 200ms ease;
      fill: $gray5;
    }
  }
}

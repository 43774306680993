.listbox-component {
  &__text {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.autocompleteDiffers {
  &__limitTag {
    background: rgba(0, 0, 0, 0.08);
    font-size: 13px;
    color: #35268f;
    font-weight: 600;
    border-radius: 50%;
    padding: 5px 10px;
  }
}

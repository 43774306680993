.campaignDate {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
  flex-wrap: wrap;
  border-right: -5px !important;
  padding: 0 !important;
}

.input-wrapper__divisor {
  width: 503px !important;
}

.space {
  width: 15px !important;
}

.dateInput {
  flex: 0 1 auto !important;
  margin: auto !important;
}

@import '../../styles/variables.scss';

.notifications {
  position: fixed;
  z-index: 91;
  right: 0;
  top: 0;
  width: 90%;
  max-width: 400px;
  height: 100%;
  max-height: 100%;
  background: $white;
  box-shadow: -6px 0px 12px rgba(0, 0, 0, 0.05);

  &--closed {
    display: none;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 100%;
    box-sizing: border-box;
  }

  h2 {
    flex: 0 1 auto;
    padding: 24px;
    padding: 24px 24px 0 24px;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 24px 16px 0 16px;
    }
  }
}

.img-close {
  position: absolute;
  right: 10px;
  top: 20px;
}

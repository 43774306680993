@import '../../styles/variables.scss';

.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  z-index: 209;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    animation: plimplim 1.5s infinite;
  }

  &__absolute {
    position: absolute;
  }

  &__relative {
    position: relative;
  }

  &__min-height {
    min-height: 60vh;
  }
}

.wrapper {
  width: 72px;
  height: 72px;
  border-radius: 30px;
  background: $white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes plimplim {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.1) rotate(5deg);
  }

  30% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.2) rotate(-5deg);
  }
}

.top-content {
  font-weight: 600;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  margin: 20px 0;
}

.digital-planning {
  display: flex;
  flex-direction: column;
  &__loading {
    background-color: #fff;
  }
}

.paragraph__alert {
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  color: #352a7a;
  font-weight: 600;
}
.group__options nav {
  position: absolute;
  right: -51px;
}

@import '../../../../../styles/variables.scss';

.available-impressions-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 24px;
  border-radius: 8px;
  border: 1px solid $gray1-2bold;
  // tira sombra
  box-shadow: none !important;

  &__title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #333;
    display: flex;
    color: $black-text;
  }

  &__top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__value {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    margin: 0;
    color: $black-text;
  }
}

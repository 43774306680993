.tags {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.tag {
  background-color: #e0e0e0;
  border-radius: 2rem;
  padding: 0.5rem 0.625rem;
  font-size: 12px;
  font-weight: 600;
  color: #515151;
  -webkit-font-smoothing: subpixel-antialiased;
}

.tag-extra {
  background-color: #bdbdbd;
  color: #515151;
}

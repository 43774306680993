.summary {
  .filters {
    &__label {
      font-weight: 700;
      font-size: 16px;
      &-content {
        font-size: 16px;
      }
    }
  }
}

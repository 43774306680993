@import './variables.scss';
@import './helpers.scss';

* {
  vertical-align: baseline;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  outline: none;
}

@font-face {
  font-family: 'Globo';
  src: url('../assets/fonts/Globotipo-WebRegular.woff2') format('woff2'),
    url('../assets/fonts/Globotipo-WebRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Globo';
  src: url('../assets/fonts/Globotipo-WebSemibold.woff2') format('woff2'),
    url('../assets/fonts/Globotipo-WebSemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Globo';
  src: url('../assets/fonts/Globotipo-WebBold.woff2') format('woff2'),
    url('../assets/fonts/Globotipo-WebBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Globo';
  src: url('../assets/fonts/Globotipo-WebBlack.woff2') format('woff2'),
    url('../assets/fonts/Globotipo-WebBlack.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root {
  --font-default: 'Globo', sans-serif;
  --toastify-font-family: var(--font-default) !important;
}

html,
body {
  font-family: $font;
  padding: 0;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}

.font__default {
  font-family: $font !important;
}

.cls-1 {
  fill: $gray4;
}

.cls-3 {
  stroke: rgba(0, 0, 0, 0);
  fill: url(#linear-gradient);
}

.cls-4 {
  stroke: rgba(0, 0, 0, 0);
  fill: $blue;
}

a {
  text-decoration: none;
  color: $gray4;
}

p {
  margin: 0;
  padding: 0;
}

.flex-sb {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.btn {
  font-family: $font;
  height: 48px;
  padding: 1px;
  border: 0;
  margin: 8px 0;
  border-radius: 10px;
  background: linear-gradient($bg-color2, $bg-color2), $blue;
  background-origin: border-box;
  background-clip: content-box, border-box;
  color: $blue;
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;

  &--export-import {
    font-size: 13px;
    width: auto !important;
    padding: 0 10px;
    margin: 0 5px 16px 0;
  }

  &--gray {
    border: 1px solid $gray2;
    color: $gray5;
    background: transparent !important;
    font-weight: bold;

    &:hover {
      background: $hover-c1 !important;
      color: $gray5 !important;
    }
  }

  &--transparent {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue;
    color: $blue;
    background: transparent !important;

    &:hover {
      background: $blue !important;
      color: $white !important;
    }
  }

  &--blue {
    background: $blue !important;
    color: $white !important;
  }

  &--mini {
    width: 80px;
  }

  &--gray {
    color: $gray5 !important;
  }

  &--med {
    width: 150px;

    @media screen and (max-width: 768px) {
      height: 35px;
      border-radius: 14px;
    }
  }

  &--full {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  &--square {
    height: 35px;
    min-width: 35px;
    margin-right: 15px;
    border-radius: 14px;
  }

  &--disabled {
    pointer-events: none;
    cursor: default !important;
    box-shadow: none !important;
    opacity: 0.6;
  }

  &:hover {
    color: $white;
    background: $blue;
    background-size: 600% 600%;
    animation: HoverMainBtn 10s ease infinite;
  }

  &--zero {
    background: transparent;
    border: 0;

    &:hover {
      color: $gray5;
      background: $hover-c1;
    }
  }
}

@keyframes HoverMainBtn {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 20px;
}

h3 {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: $gray5;
  margin-bottom: 8px;
}

::-moz-selection {
  background: #ddc4ff;
}

::selection {
  background: #ddc4ff;
}

.ipt {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  font-family: $font;
  font-size: 14px;
  width: 240px;
  // height: 48px;
  line-height: 1.5;
  overflow: hidden;
  margin-bottom: 8px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 400px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &--half {
    width: 160px;
    margin-right: 8px;

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 400px;
    }
  }

  &--full {
    width: 100%;
  }

  > img {
    position: absolute;
    top: 5px;
    bottom: 0;
    margin: auto 0;
    right: 12px;
    pointer-events: none;
  }
}

.label-title {
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  color: $black-coral;
  margin-bottom: 8px;
}

.label-sub {
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  color: $gray5;
  display: block;
  margin-bottom: 4px;
}

.text-info {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: $gray3;
  display: block;
  padding: 0 0 16px 0;

  svg {
    vertical-align: bottom;
    margin-right: 8px;
  }

  &-2 {
    border: 1px dashed $info-border;
    border-radius: 12px;
    font: normal normal normal 14px/19px $font;
    letter-spacing: 0px;
    color: $info-text;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    height: 96px;
    overflow: hidden;
  }
}

.ml8 {
  margin-left: 8px;
}

.ml16 {
  margin-left: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.pt0 {
  padding-top: 0 !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &__divisor {
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__divisor_select {
    position: relative;
    display: flex;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__divisor__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-top: 15px;
    }
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.main-content {
  width: 100%;

  &__wrapper {
    width: 100%;
    max-width: 1800px;
    padding: 24px 40px 100px 40px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: 768px) {
      padding: 24px 16px 250px 16px;
    }
  }
}

.no-more {
  margin: 24px auto 0 auto;
  display: block;
  font-size: 14px;
  text-align: center;
  color: $gray3;
}

.btn-square {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;

  &:hover {
    background: $hover-c1;
  }

  &--mini {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    margin-top: -1px;
  }
}

.flex-wrap {
  display: flex;
  align-items: center;
}

.rdg {
  border-radius: 8px;
  background: $white;
  border: 1px solid #e0e0e0;

  > div {
    &:hover {
      background: #fffbf3;
      width: var(--row-width);
    }

    &:first-child {
      div {
        color: $gray5;
        font-weight: bold;
        font-size: 15px;
        font-family: $font;
      }
    }
  }

  .c1wupbe700-beta7[aria-selected='true'] {
    box-shadow: inset 0 0 0 2px $gray3;
  }
}

.overflow__hidden {
  overflow: hidden;
}

.warning-msg {
  font-size: 12px;
  color: $purple;
}

.no-message {
  width: 100% !important;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none !important;
}

.fill-height {
  height: 100% !important;
}

.fill-width {
  width: 100% !important;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text__uppercase {
  text-transform: uppercase !important;
}

.text_capitalize {
  text-transform: capitalize;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scrollable-text {
  height: 130px;
  border-radius: 4px;
  border: solid 1px $gray1;
  overflow-y: scroll;
  padding: 10px;
  font-size: 15px;
}

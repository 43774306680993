// @import '../../styles/variables.scss';

.users {
  &__grid--height {
    height: 409px;
  }

  &__grid--width {
    grid-template-rows: max-content;
  }
}

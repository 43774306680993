@import '../../styles/variables.scss';

.modal-container {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100%;
  // height: 100%;
  // background: rgba(39, 39, 39, 0.75);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // z-index: 199;

  &__wrapper {
    // max-width: 286px;
    // max-height: 95%;
    // overflow-y: auto;
    border-radius: 30px !important;
    // background: $white;
    // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    // padding: 20px 24px 16px 24px;
    // box-sizing: border-box;

    @media screen and (max-width: 600px) {
      border-radius: 0px !important;
    }
  }

  &__title {
    font: normal normal bold 16px/22px $font !important;
    letter-spacing: 0px !important;
    text-align: left;
    color: $gray5;
  }
}

.no-padding-left {
  padding-left: 0px !important;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
